import { ProductionInstance } from "@/instance/axios";
import {
  createMutationKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  ProductionActionApiWorksGroupPerformancePutRequest,
  ProductionActionApiWorksWorkIdPerformancePutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const mutatePerformance = createMutationKeys("performance", {
  update: (params: ProductionActionApiWorksWorkIdPerformancePutRequest) => ({
    mutationKey: [params],
    mutationFn: () => ProductionInstance.worksWorkIdPerformancePut(params),
  }),

  groupPerformance: (
    params: ProductionActionApiWorksGroupPerformancePutRequest
  ) => ({
    mutationKey: [params.worksGroupPerformancePutRequest],
    mutationFn: () => ProductionInstance.worksGroupPerformancePut(params),
  }),
});

export const defectQueryKeys = mergeQueryKeys(mutatePerformance);
