import { BaseButton } from "@/components/base/button/BaseButton";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum,
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconPlayerPause,
  IconPlayerPlay,
  IconSquareOff,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

interface WorkStatusButtonProps {
  externalCounterSetting?: ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum;
  trackingStatus?: ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;
  handleFlush?: () => void;
  handleStart?: () => void;
  handlePause?: () => void;
  handleCorrect?: () => void;
  handleEnd?: () => void;
  handleResume?: () => void;
}

export const WorkStatusButton = ({
  externalCounterSetting,
  trackingStatus,
  handleFlush,
  handleStart,
  handlePause,
  handleCorrect,
  handleResume,
  handleEnd,
}: WorkStatusButtonProps) => {
  const isStart =
    externalCounterSetting !== "HANDWRITING" && trackingStatus === "WAITING";
  const isPauseAndEnd = trackingStatus === "WORKING";
  const isResume = trackingStatus === "PAUSED";
  const { t } = useTranslation();

  const buttonProps = () => {
    if (isStart) {
      return {
        color: "green.6",
        icon: <IconPlayerPlay size={"2rem"} />,
        onClick: handleStart,
        text: "작업시작",
      };
    } else if (isPauseAndEnd) {
      return [
        {
          color: "orange.6",
          onClick: handlePause,
          text: "일시정지",
          icon: <IconPlayerPause size={"2rem"} />,
        },
        {
          color: "red",
          onClick: handleEnd,
          text: "작업종료",
          icon: <IconSquareOff size={"2rem"} />,
        },
      ];
    } else if (isResume) {
      return {
        color: "green.6",
        onClick: handleResume,
        text: "작업재개",
        icon: <IconPlayerPlay size={"2rem"} />,
      };
    }
  };

  const renderButtons = () => {
    const props = buttonProps();

    if (Array.isArray(props)) {
      return props.map((prop, index) => (
        <BaseButton
          key={index}
          color={prop.color}
          onClick={prop.onClick}
          leftIcon={prop.icon}
        >
          {t(prop.text)}
        </BaseButton>
      ));
    } else if (props) {
      return (
        <BaseButton
          color={props.color}
          onClick={props.onClick}
          leftIcon={props.icon}
        >
          {t(props.text)}
        </BaseButton>
      );
    }
  };

  return <>{renderButtons()}</>;
};
