import { MantineSelectableTable } from "@/components/table/MantineSelectableTable";
import { useNonStandardWorkQuery } from "@/hooks/useNonStandardWorkQuery.hook";
import { useWorkDataStore } from "@/store/work.store";
import { WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

export const TableSection = () => {
  const { workData, setSelectedRows, selectedRows } = useWorkDataStore(
    (state) => ({
      workData: state.workData,
      setSelectedRows: state.setSelectedRows,
      selectedRows: state.selectedRows,
    })
  );

  const { work } = workData;

  const { data } = useNonStandardWorkQuery({
    routingOutsourceName: work?.routingOutsourceData?.name ?? "",
    equipmentName: work?.equipmentName ?? "",
    itemName: work?.item?.name ?? "",
  });

  const columns = useMemo<MRT_ColumnDef<WorksItemGet200ResponseRowsInner>[]>(
    () => [
      {
        accessorKey: "itemName",
        header: "제품명",
      },
      {
        accessorKey: "spec",
        header: "규격",
      },
      {
        accessorKey: "targetQuantity",
        header: "목표 생산량",
      },
      {
        accessorKey: "end",
        header: "실적 수량",
      },
    ],
    []
  );

  const convertedData = data?.data?.rows?.map((row) => ({
    id: row.id,
    itemName: row.itemName,
    spec: row?.spec,
    targetQuantity: row.targetQuantity,
    end: row?.summary?.end,
    todoQuantity: row?.summary?.todoQuantity,
  }));

  const handleRowSelection = (
    selectedRows: WorksItemGet200ResponseRowsInner[]
  ) => {
    setSelectedRows(selectedRows); // Zustand 상태 업데이트
  };

  return (
    <MantineSelectableTable
      columns={columns}
      data={convertedData ?? []}
      onRowSelection={handleRowSelection}
      doReset={!selectedRows.length}
    />
  );
};
