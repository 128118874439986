import { RawMaterialInputForm } from "@/components/form/material/RawMaterialForm";

import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";

import { PalletBarcodeBox } from "@/components/form/pallet/palletBarcodeBox";
import { DefectObject } from "@/components/section/WorkCurrentDefectComponent";
import { customFunctions } from "@/config/customFunctions";
import useConfirmModal from "@/hooks/useConfirmModal.hook";
import useWorkActions from "@/hooks/useWorkActions.hook";
import i18n from "@/language/i18n";
import { useEquipmentStore } from "@/store/equipment.store";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import { WorksWorkIdPerformancePutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import BigNumber from "bignumber.js";
import { t } from "i18next";

export const usePerformanceActions = () => {

    const { tempWork, setTempWork } = useLocalStorage();
    const { openModal, closeModal } = useModal();
    const { workData } = useWorkDataStore((state) => ({ workData: state.workData }));
    const { equipment } = useEquipmentStore((state) => ({ equipment: state.Equipment }));

    const { work, inventories, lots } = workData;
    const { onFlush, onPerformance, defectMutateAsync } = useWorkActions({ workData: work });
    const { confirmFlushModal, confirmOutOfStockModal, confirmPreviousOrderClosureModal, confirmLimitHitModal } = useConfirmModal();


    const activeWorkId = work?.equipment?.activeWorkId;
    const isActiveWorkInProgress = activeWorkId !== work?.id && activeWorkId !== null;

    const isWaitingStatus = work?.trackingStatus === "WAITING";
    const isInventory = inventories?.rows?.length as number > 0;
    const isFirstPerformance = work?.summary?.end === '0';
    const isFlush = isFirstPerformance && isInventory && isWaitingStatus

    const currentTempDefectList = Object.keys(tempWork?.defects ? tempWork?.defects as DefectObject : {}).map((code) => {
        return {
            defectName: (tempWork?.defects as DefectObject)[code]?.defectName as string,
            defectCode: code,
            defectQuantity: (tempWork?.defects as DefectObject)[code]?.defectQuantity as string,
            setUnitText: work?.item?.unitText
        }
    })

    const currentTempDefectTotal = currentTempDefectList.reduce((acc, cur) => (BigNumber(acc).plus(cur.defectQuantity as string).toString()), '0')

    const validateDefectQuantities = () => {

        if (!tempWork?.defects) return false;

        for (const code of Object.keys(tempWork.defects)) {

            if ((tempWork.defects[code]?.defectQuantity) === '0') continue

            const currentLotDefectQuantity = (work?.currentLotSummary?.defect as DefectObject)?.[code]?.defectQuantity as string;
            const tempDefectQuantity = tempWork.defects[code]?.defectQuantity as string;

            if (BigNumber(currentLotDefectQuantity).isGreaterThanOrEqualTo(tempDefectQuantity)) {
                const defectName = (work?.currentLotSummary?.defect as DefectObject)?.[code].defectName;
                customNotification.error({ message: `${defectName} ${t("의 임시 수량이 현재 로트의 불량 수량보다 적습니다.")}` });
                return true;
            }
        }

        return false;
    };


    // 실적입력에 관련한 유효성 검사를 하는 함수
    const validatePerformance = (quantity: string) => {

        const totalEnd = BigNumber(work?.currentLotSummary?.end ?? '0').plus(quantity);
        const totalDefects = BigNumber(currentTempDefectTotal).plus(work?.currentLotSummary?.defectTotal ?? '0');
        const isDefectsGreaterThanEnd = totalDefects.isGreaterThan(totalEnd);
        const isQuantityLessThanTotal = BigNumber(quantity).isLessThanOrEqualTo(work?.currentLotSummary?.totalQuantity as string);
        const isValidDefectQuantity = validateDefectQuantities();

        //1. 임시 불량수량이 입력한 실적 수량보다 많을때
        if (isDefectsGreaterThanEnd) {
            customNotification.error({ message: t("불량 수량이 입력한 실적 수량보다 많습니다.") });
            return false;
        }

        //2. 내가 입력한 수량보다 현재 로트의 총 수량이 더 클때
        if (isQuantityLessThanTotal) {
            customNotification.error({ message: t("생산 수량이 입력한 실적 수량보다 같거나 많습니다.") });
            return false;
        }

        //3. 임시 불량수량이 현재 로트의 불량수량보다 적을때
        if (isValidDefectQuantity) return false;

        return true

    }

    // 초기화(플러싱) 여부를 확인하는 함수
    const handleFlush = async () => {
        if (isFlush) {
            const confirmFlush = await confirmFlushModal({ workInventoriesData: inventories });
            if (confirmFlush) { onFlush(); }
        }
        return false
    };

    // 이전 작업 지시서가 종료를 확인하는 함수
    const handlePreviousOrderClosure = async () => {
        if (isActiveWorkInProgress) {
            const confirmClosure = await confirmPreviousOrderClosureModal();
            if (!confirmClosure) return false;
        }
        return true;
    };

    // 불량 저장에 대한 함수
    const handleDefectSave = async () => {

        for (const defect of currentTempDefectList) {
            if (defect.defectQuantity === '0') continue;
            try {
                await defectMutateAsync({
                    workId: work?.id as number,
                    worksWorkIdDefectPutRequest: {
                        defectCode: defect.defectCode + "",
                        accQuantity: BigNumber(defect.defectQuantity as string).toString(),
                        lotId: lots?.id
                    }
                }, {
                    onSuccess: () => {
                        setTempWork({
                            ...tempWork,
                            defects: {}
                        })
                    }
                });
            } catch (error: any) {
                customNotification.error({ message: error?.response?.data?.message ?? t('불량 저장에 실패했습니다.') });
                return;  // Stop the loop if there is an error
            }
        }
    }

    // 실적 저장에 대한 함수
    const handlePerformance = async (options: WorksWorkIdPerformancePutRequest) => {

        const handleOnSuccess = () => {
            if (currentTempDefectList.length > 0) {
                handleDefectSave();
            }
            closeModal({});
        };

        const handleOnError = async (e: any) => {
            const errorMessage = e.response?.data?.message;
            const isError = [
                "accQuantity is less than current accQuantity",
                "Quantity must be positive",
                "accQuantity 값이 현재 현재 accQuantity값보다 작습니다."
            ].includes(errorMessage);

            if (isError) {
                return customNotification.error({ message: t("이전 생산수량보다 큰 수량을 입력해주세요.") });
            }

            const confirmOutOfStock = await confirmOutOfStockModal();
            if (confirmOutOfStock) {
                await openModal(<RawMaterialInputForm workData={work} />, '', t('원부자재 투입'));
            }

            await retryOnPerformance();
        };

        const retryOnPerformance = async () => {
            await onPerformance(
                { ...options, warnInsufficiency: false },
                { onSuccess: handleOnSuccess }
            );
        };

        await onPerformance(
            options,
            {
                onSuccess: handleOnSuccess,
                onError: handleOnError,
            }
        );
    };


    // 실적입력 함수 + 유효성 검사 함수 + 플러시 여부 확인 함수 + 이전 작업 지시서 종료 여부 확인 함수
    const handlePerformanceWithValidation = async (quantity: string): Promise<boolean> => {

        const previousOrderClosed = await handlePreviousOrderClosure();
        if (!previousOrderClosed) return false;

        if (customFunctions.ADD_TEMP_FUNCTION_ENABLED) {
            const isValid = validatePerformance(quantity);
            if (!isValid) return false;
        }

        if (customFunctions.ADD_FLUSHING) {
            await handleFlush();
        }

        await handlePerformance(
            customFunctions.ADD_ACCUMULATION_QUANTITY ?
                { accQuantity: quantity, warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY }
                : { quantity: quantity, warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY }
        )
        return true;
    }

    // 파레트 분리 함수 
    const handlePalletSeparate = async () => {
        return openModal(<PalletBarcodeBox workData={work} lotData={lots} />, "", i18n.t('투입 기록 확인'))
    }

    // 리밋 카운트 도달시 띄우는 함수 
    const handleLimitCounter = async () => {
        const confirmLimitHit = await confirmLimitHitModal({
            unitText: work?.item?.unitText,
            equipmentLimitCounter: equipment?.limitCount,
            defectTotal: currentTempDefectTotal
        });

        if (confirmLimitHit) {
            await handlePerformanceWithValidation(equipment?.limitCount as string);
        }
    }

    return {
        handlePerformanceWithValidation,
        handlePerformance,
        handleFlush,
        handlePreviousOrderClosure,
        handleDefectSave,
        handleLimitCounter,
        handlePalletSeparate
    }

}