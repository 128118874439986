import BaseAccordion from "@/components/base/BaseAccordion";
import BaseProgress from "@/components/base/BaseProgress";
import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { useWorkDataStore } from "@/store/work.store";
import { Accordion } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner, WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { t } from "i18next";

const WorkTimeTrack = Object.assign({}, {
    Title: BaseTitle,
    Text: BaseText,
    Table: BaseTable,
    Progress: BaseProgress,
    Accordion: BaseAccordion
})

type ExtendedWorkLogs = WorkLogsGet200ResponseRowsInner & { endTime?: string };

export const WorkTimeTrackComponent = () => {

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { inputLogsAll } = workData

    const workTrackLogsData = inputLogsAll?.filter((data) => (
        data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME
        || data.workLogType === WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START
    )).reverse()

    const renderWorkTrackLogs = () => {
        return workTrackLogsData?.reduce((acc, row, index, self) => {
            // 일시정지인 경우 작업재개가 있는경우 작업재개를 찾아서 endTime에 종료시간을 추가
            const extendedRow = { ...row } as ExtendedWorkLogs;
            if (extendedRow.workLogType === "TIME_TRACKING_PAUSE") {
                const nextResumeIndex = self.slice(index + 1).findIndex((r) => r.workLogType === "TIME_TRACKING_RESUME");
                if (nextResumeIndex !== -1) {
                    extendedRow.endTime = self[index + nextResumeIndex + 1].createdAt;
                }
            } else if (extendedRow.workLogType === "TIME_TRACKING_END") {
                extendedRow.endTime = extendedRow.createdAt;
            }
            // 작업로그 중 작업 재개가 아닌경우 배열(acc)에 추가
            if (extendedRow.workLogType !== "TIME_TRACKING_RESUME") {
                acc.push(extendedRow);
            }
            return acc;
        }, [] as ExtendedWorkLogs[]).map((row) => {
            const workLogTypeText = row.workLogType === "TIME_TRACKING_START" ? "작업 시작" :
                row.workLogType === "TIME_TRACKING_END" ? "작업 종료" :
                    row.workLogType === "TIME_TRACKING_PAUSE" ? "일시 정지" : "";
            const startTime = row.workLogType !== "TIME_TRACKING_END" ? dayjs(row.createdAt).format("YYYY-MM-DD HH:mm:ss") : "-";
            const endTime = row.endTime ? dayjs(row.endTime).format("YYYY-MM-DD HH:mm:ss") : "-";
            const downtimeReasonName = row.workLogType === "TIME_TRACKING_PAUSE" ? (row.downtimeReasonName as string) : "-";

            return [
                t(workLogTypeText),
                startTime,
                endTime,
                downtimeReasonName,
            ];
        });
    };

    return (
        <WorkTimeTrack.Accordion>
            <Accordion.Item value="workTable">
                <Accordion.Control>
                    <WorkTimeTrack.Title
                        fz={"1.625rem"}
                        c={"#FFFFFF"}
                    >
                        {t("작업시간 추적")}
                    </WorkTimeTrack.Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <WorkTimeTrack.Table
                        columns={[t("타입"), t("시작시간"), t("종료시간"), t("비가동 사유")]}
                        flex={[1, 2, 2, 2]}
                        data={renderWorkTrackLogs()}
                    />
                </Accordion.Panel>
            </Accordion.Item>
        </WorkTimeTrack.Accordion>
    )
}