import useSpcInspectionsGetQuery from "@/api/inspections/useInspectionsGetQuery";
import { InspectionsRow } from "@/components/row/InspectionsRow";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import styled from "@emotion/styled";
import { Flex, Pagination, Table, Text } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Inspections = () => {
  const [activePage, setPage] = useState<number>(1);
  const { equipmentCode } = useLocalStorage();
  const { t } = useTranslation();
  const { data } = useSpcInspectionsGetQuery({
    activePage,
    sort: "-createdAt, -inspectionAt",
    query: {
      $and: [
        (equipmentCode !== null && equipmentCode !== 'null') ? { equipmentCode: { $eq: equipmentCode } } : {}
      ],
    },
    populate: ['lot']
  });

  return (
    <TableContainer>
      <Table verticalSpacing={"xl"} horizontalSpacing={"xl"}>
        <Thead>
          <tr>
            <Th width={20}>
              <Text fz="md" fw={500}>
                {t("로트명")}
              </Text>
            </Th>
            <Th width={18}>
              <Text fz="md" fw={500}>
                {t("품목코드")}
              </Text>
            </Th>
            <Th width={25}>
              <Text fz="md" fw={500}>
                {t("측정일")}
              </Text>
            </Th>
            <Th width={25}>
              <Text fz="md" fw={500}>
                {t("검사 기준 요약")}
              </Text>
            </Th>
            <Th width={12}>
              <Text fz="md" fw={500}>
                {t("합격 여부")}
              </Text>
            </Th>
          </tr>
        </Thead>
        <Tbody>
          {data?.data?.rows?.map((inspection, index) => {
            return (
              <>
                <InspectionsRow data={inspection} key={index} />
              </>
            );
          })}
        </Tbody>
      </Table>
      <Flex justify={"center"} w={"100%"} mt={50}>
        <Pagination
          align="center"
          onChange={setPage}
          value={activePage}
          total={data?.data?.totalPages ?? 0}
          size="md"
          radius="sm"
        />
      </Flex>
    </TableContainer>
  );
};

const Thead = styled.thead`
  width: 100%;
`;
const Tbody = styled.tbody`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => props.width ? props.width : 'auto'}%;
`

// const Th = styled.th<{ flex?: number; seq?: number }>`
//   display: flex;
//   flex-direction: column;
//   flex : ${(props) => (props.flex ? props.flex : 1)};
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   white-space: pre-line;
//   word-break: break-all;
//   /* border: 0.0625rem solid ${theme.colors?.gray?.[3]}; */
// `;

const TableContainer = styled.div`
  overflow: auto;
  height: 100%;
`;