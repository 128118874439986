import { defects } from "@/api/defects/useDefectsQuery";
import { inventories } from "@/api/inventories/useInventoriesQuery";
import { workLogs } from "@/api/logs/useWorksLogsQuery";
import { lots } from "@/api/lots/useLotsQuery";
import { routingOutsources } from "@/api/routingOutsources/useRoutingOutsourcesQuery";
import { works } from "@/api/work/useWorkQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useEquipmentStore } from "@/store/equipment.store";
import { usePaginationStore } from "@/store/pagenation.store";
import { useWorkDataStore } from "@/store/work.store";

import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "react-query";



export const useGetWorkData = () => {

    const { workId } = useLocalStorage();
    const { setEquipment } = useEquipmentStore((state) => ({
        setEquipment: state.setEquipment,
    }))

    const { setWorkData } = useWorkDataStore((state) => ({
        setWorkData: state.setWorkData,
    }));

    const { currentPage } = usePaginationStore((state) => (
        { currentPage: state.currentPage }
    ));

    const { data: defectsData } = useQuery(defects.find({}));

    const { data: workData } = useQuery({
        ...works.detail({ workId }, {
            params: {
                defectCodes: defectsData?.data?.map((row) => row.code),
                populate: ["summary", "item", "equipment",
                    "routingData", "productionPlan", "locationSetting",
                    "currentLotSummary", "currentRoutingOutsourceData"],
            },
        }), enabled: !!workId && !!defectsData,
        onSuccess: (data) => {
            const work = data?.data;
            if (!work.equipmentCode || !work.equipmentName) return;
            setEquipment({
                name: work.equipmentName,
                code: work.equipmentCode
            });

            setWorkData({ work })
        },
        cacheTime: 0,
    });

    const { data: workLotsData } = useQuery({
        ...lots.detail({ lotId: workData?.data?.productionPlan?.lastLotId as number }),
        enabled: !!workData?.data?.productionPlan?.lastLotId,
        onSuccess: (data) => {
            const lots = data?.data;
            setWorkData({ lots })
        },
        cacheTime: 0,
    });

    const { data: workInventoriesData } = useQuery({
        ...inventories.get({
            query: {
                $and: [
                    {
                        itemCode: workData?.data?.routingData?.routingBoms?.map((bom) => bom.itemCode),
                    },
                    { locationCode: workData?.data?.equipment?.toLocationCode ?? workData?.data?.currentRoutingOutsourceData?.toLocationCode ?? workData?.data?.locationSetting?.toLocation?.code ?? workData?.data?.routingData?.operation?.toLocationCode },
                    { quantity: { $ne: 0 } },
                ],
            },
            populate: ["item", "lot"],
        }), enabled: !!workData,
        onSuccess: (data) => {
            const inventories = data?.data;
            setWorkData({ inventories })
        },
        cacheTime: 0,
    });

    const { data: workInputLogsAll } = useQuery({
        ...workLogs.find({
            query: {
                $and: [
                    { workId: workId },
                    {
                        $or: [
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME } },
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START } },
                        ],
                    },
                ],
            },
            populate: ["downtimeReasonName", "defectName", "itemUnit", "alreadyCanceled", "alreadyCanceledWorkLog"],
            sort: "-createdAt",
        }), enabled: !!workId,
        onSuccess: (data) => {
            const inputLogsAll = data?.data;
            setWorkData({ inputLogsAll })
        },
        cacheTime: 0,

    });

    const { data: workInputLogs } = useQuery({
        ...workLogs.get({
            query: {
                $and: [
                    { workId: workId },
                    {
                        $or: [
                            { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
                        ],
                    },
                ],
            },
            page: currentPage,
            populate: ["downtimeReasonName", "defectName", "itemUnit", "alreadyCanceled", "alreadyCanceledWorkLog"],
            sort: "-createdAt",
        }), enabled: !!workId,
        onSuccess: (data) => {
            const inputLogs = data?.data;
            setWorkData({ inputLogs })
        },
        cacheTime: 0,
    });

    const { data: workOutsourceData } = useQuery({
        ...routingOutsources.detail({ routingOutsourceId: workData?.data?.routingOutsourceId as number }),
        enabled: !!workData?.data?.routingOutsourceId,
        onSuccess: (data) => {
            const outsource = data?.data;
            setWorkData({ outsource })
        },
        cacheTime: 0,
    });

    const { data: workAllLogs } = useQuery({
        ...workLogs.get({
            query: {
                $and: [{ workId: { $eq: workId ?? 0 } },
                { parentWorkLogId: { $eq: null } },
                {
                    $or: [
                        { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT } },
                        { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT } },
                        { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE } },
                        { workLogType: { $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.RECYCLE } },
                    ],
                },
                ],
            },
            sort: "-id",
            page: currentPage,
            populate: ["itemUnit", "defectName", "creatorUser", "alreadyCanceledWorkLog"],
        }),
        onSuccess: (data) => {
            const logsAll = data?.data;
            setWorkData({ logsAll })
        },
        cacheTime: 0,
    })

    return { defectsData, workData, workLotsData, workInventoriesData, workInputLogs, workInputLogsAll, workOutsourceData, workAllLogs };

}