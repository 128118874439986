import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
interface InventoryRowProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
}

export const InventoryRow = (params: InventoryRowProps) => {
  const {
    itemCode,
    lot,
    locationCode,
    quantity,
    increaseQuantity,
    decreaseQuantity,
    deltaQuantity,
    closedQuantity,
    closedAt,
    unitText,
  } = params.data;

  return (
    <tr>
      <td>
        <Text c={"#FFFFFF"} align="left">
          {itemCode}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="left" style={{ wordWrap: "break-word" }}>
          {lot?.name}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="left">
          {lot?.expiration ? dayjs(lot?.expiration).format("YYYY-MM-DD") : "-"}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="left">
          {locationCode}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="right">
          {setToLocaleString(quantity)} {unitText}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="right">
          {closedQuantity !== null
            ? setToLocaleString(closedQuantity) + " " + unitText
            : "-"}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="left">
          {timeUtil(closedAt)}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="right">
          {setToLocaleString(increaseQuantity)} {unitText}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="right">
          {setToLocaleString(decreaseQuantity)} {unitText}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} align="right">
          {setToLocaleString(deltaQuantity)} {unitText}
        </Text>
      </td>
    </tr>
  );
};
