import { works } from "@/api/work/useWorkQuery";
import { useQuery } from "react-query";

interface UseNonStandardWorkQueryParams {
  routingOutsourceName: string;
  equipmentName: string;
  itemName: string;
}

export const useNonStandardWorkQuery = ({
  routingOutsourceName,
  equipmentName,
  itemName,
}: UseNonStandardWorkQueryParams) => {
  return useQuery({
    ...works.itemWork({
      query: {
        $and: [
          { routingOutsourceName: routingOutsourceName || "NoData" },
          { equipmentName: equipmentName || "NoData" },
          { itemName: itemName || "NoData" },
        ],
      },
      pageSize: 10,
      sort: "id",
      populate: [
        "equipment",
        "summary",
        "item",
        "productionPlan",
        "routingData",
      ],
    }),

    enabled: !!routingOutsourceName && !!equipmentName,
  });
};
