import useWorkLogsWorkLogIdCancelDefectPut from "@/api/cancelDefect/useWorkLogsWorkLogIdCancelDefectPut";
import useWorkLogsWorkLogIdCancelPerformancePut from "@/api/cancelPerformance/useCancelPerformance";
import useCancelInputPutMutation from "@/api/input/useCancelInputPutMutation";
import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { FormButtonBox } from "@/components/form/FormButtonBox";
import { FormMain } from "@/components/form/FormMain";
import { FormWrapper } from "@/components/form/FormWrapper";
import {
  getColorOfLogType,
  getKoreanWorkLogType,
} from "@/constants/workLogType";
import { usePaginationStore } from "@/store/pagenation.store";
import { useWorkDataStore } from "@/store/work.store";
import { Badge, Button, Flex, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Logs = Object.assign({}, FormMain, {
  Button: Button,
  Description: BaseTitle,
  Text: BaseText,
  Table: BaseTable,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Pagination: Pagination,
});

export function LogsForm() {
  const { mutate: cancelPerformance } =
    useWorkLogsWorkLogIdCancelPerformancePut();
  const { mutate: cancelInput } = useCancelInputPutMutation("cancel");
  const { mutate: cancelDefect } = useWorkLogsWorkLogIdCancelDefectPut();
  const { t } = useTranslation();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));
  const { logsAll } = workData;

  const onCancel = ({
    worLogId,
    logType,
  }: {
    worLogId: number;
    logType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum;
  }) => {
    if (!window.confirm(t("해당 작업을 취소하시겠습니까?"))) {
      return;
    }

    switch (logType) {
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE:
        cancelPerformance({
          workLogId: worLogId,
          workLogsWorkLogIdCancelInputPutRequest: {
            createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          },
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT:
        cancelDefect({
          workLogId: worLogId,
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT:
        cancelInput({
          workLogId: worLogId,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  return (
    <Logs.Wrapper>
      <Logs.Description c={"#010000"} size={"sm"} fw={400}>
        {t("작업 로그(투입, 실적, 불량)를 확인합니다.")}
      </Logs.Description>
      <Logs.Description c={"#000000"} size={"sm"} fw={400}>
        {t("취소 버튼을 클릭하여 특정 작업을 취소할 수 있습니다.")}
      </Logs.Description>
      <Logs.Table
        fontSize={"sm"}
        withBorder={false}
        withColumnBorders={false}
        theadColor={"#FFFFFF"}
        flex={[1, 1, 2, 1, 2, 1, 1, 1]}
        trColor={"#000000"}
        columns={[
          t("로그 번호"),
          t("연관 로그 번호"),
          t("일시"),
          t("타입"),
          t("로트명"),
          t("수량"),
          t("작업자"),
          t("액션"),
        ]}
        data={logsAll?.rows?.map((log) => {
          const logType = getKoreanWorkLogType(
            log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
          );
          const colorOfLogType = getColorOfLogType(
            log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
          );
          const lotName =
            log?.workLogType === "INPUT" || log.workLogType === "RECYCLE"
              ? log.logData?.incoming?.lotData?.name !== null
                ? log.logData?.incoming?.lotData?.name
                : "-"
              : undefined;

          return [
            <Logs.Text align="right" c={"dark"} size={"xl"}>
              {log?.id ?? "-"}
            </Logs.Text>,
            <Logs.Text c={"dark"} size={"xl"}>
              {log.parentWorkLogId ?? "-"}
            </Logs.Text>,
            <Logs.Text c={"dark"} size={"xl"}>
              {dayjs(log?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
            </Logs.Text>,
            <Badge size="xl" color={colorOfLogType}>
              {t(logType)}
            </Badge>,
            <Logs.Text c={"dark"} size={"xl"}>
              {lotName}
            </Logs.Text>,
            <Logs.Text align="right" c={"dark"} size={"xl"}>
              {log?.quantity ? `${log?.quantity} ${log.unitText}` : "-"}
            </Logs.Text>,
            <Logs.Text c={"dark"} size={"xl"}>
              {log?.creatorUserName}
            </Logs.Text>,
            log.alreadyCanceledWorkLog ? (
              <Logs.Text c={"grape"} size={"xl"} ta={"center"}>
                {t("취소됨")}
              </Logs.Text>
            ) : (
              <Logs.Button
                bg={"inherit"}
                styles={{
                  root: {
                    "&:hover": {
                      background: "inherit",
                    },
                  },
                }}
                onClick={() =>
                  onCancel({
                    worLogId: log.id as number,
                    logType:
                      log.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
                  })
                }
              >
                <IconTrash color="red" />
                <Logs.Text pl={"1rem"} c={"red"} size={"xl"}>
                  {t("취소")}
                </Logs.Text>
              </Logs.Button>
            ),
          ] as string[] | React.ReactElement[];
        })}
      />
      <Flex justify={"center"}>
        <Logs.Pagination
          size={"xl"}
          value={currentPage}
          onChange={setPage}
          total={logsAll?.totalPages as number}
        />
      </Flex>
    </Logs.Wrapper>
  );
}
