export const menuList = [
  {
    id: "/works",
    name: "작업지시",
    contentTitle: "작업지시",
    // icon: <icons.DashboardIcon />,
  },
  {
    id: `/work`,
    name: "POP",
    contentTitle: "POP",
    // icon: <icons.DashboardIcon />,
  },
  {
    id: `/nonStandardWork`,
    name: "POP 비규격",
    contentTitle: "POP 비규격",
    // icon: <icons.DashboardIcon />,
  },
  {
    id: `/inventory`,
    name: "재고",
    contentTitle: "재고",
    // icon: <icons.DashboardIcon />,
  },
  {
    id: `/inspections`,
    name: "검사기록",
    contentTitle: "검사기록",
    // icon: <icons.DashboardIcon />,
  },
] as const;

type MenuItem = (typeof menuList)[number];

export type MenuId = MenuItem["id"];
export type MenuName = MenuItem["name"];
export type ContentTitle = MenuItem["contentTitle"];
