import { useLocalStorage } from "@/context/LocalStorageProvider";
import { setToLocaleString } from "@/utils/unitMark";
import { Text } from "@mantine/core";
import { WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface WorkRowProps {
  data: WorksItemGet200ResponseRowsInner;
}

export const WorkRow = (params: WorkRowProps) => {
  const {
    routingCode,
    targetQuantity,
    trackingStatus,
    equipment,
    item,
    scheduleSeq,
    createdAt,
    scheduledAt,
    erpWorkOrderSerl,
    description,
  } = params.data;

  const navigate = useNavigate();
  const { setWorkId } = useLocalStorage();

  const onClickMemorize = (work: WorksItemGet200ResponseRowsInner) => {
    setWorkId(work.id);
  };

  const onClickNavigate = (work: WorksItemGet200ResponseRowsInner) => {
    if (!work?.itemDescription) {
      return navigate(`/nonStandardWork/${work.id}`);
    }
    navigate(`/work/${work.id}`);
  };

  return (
    <tr
      color="transparent"
      onClick={() => {
        onClickMemorize(params.data);
        onClickNavigate(params.data);
      }}
    >
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="right" w={"100%"}>
          {scheduleSeq}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="left" w={"100%"}>
          {equipment?.name}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="left" w={"100%"}>
          {item?.name}({routingCode})
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="right" w={"100%"}>
          {setToLocaleString(targetQuantity)} {item?.unitText}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="right" w={"100%"}>
          {item?.spec ?? "-"}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="right" w={"100%"}>
          {dayjs(scheduledAt).format("YYYY-MM-DD")}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"md"} align="left" w={"100%"}>
          {trackingStatus === "WAITING"
            ? "대기"
            : trackingStatus === "PAUSED"
            ? "일시정지"
            : trackingStatus === "WORKING"
            ? "진행중"
            : "완료"}
        </Text>
      </td>
      <td>
        <Text c={"#FFFFFF"} size={"lg"} align="right" w={"100%"}>
          {description}
        </Text>
      </td>
    </tr>
  );
};
