import { useWorkDataStore } from "@/store/work.store";
import { Card, Grid, Text } from "@mantine/core";

const InfoSection = () => {
  const { workData, setSelectedRows } = useWorkDataStore((state) => ({
    workData: state.workData,
    setSelectedRows: state.setSelectedRows,
  }));

  const { work } = workData;

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      style={{ backgroundColor: "#1E1E1E", color: "white" }}
    >
      <Grid justify="space-between" align="center">
        <Grid.Col span={3}>
          <Text size={"xl"} fw={"bold"} c={"#9C6ADE"}>
            {work?.routingOutsourceData?.name}
          </Text>
          <Text size={"md"} c={"gray"}>
            업체명
          </Text>
        </Grid.Col>

        <Grid.Col span={3}>
          <Text size={"xl"} fw={"bold"} c={"#51CF66"}>
            {work?.equipmentName}
          </Text>
          <Text size={"md"} c={"gray"}>
            현장명(설비명)
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default InfoSection;
