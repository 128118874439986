import BaseAccordion from "@/components/base/BaseAccordion";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { customFunctions } from "@/config/customFunctions";
import { useWorkDataStore } from "@/store/work.store";
import { setToLocaleString } from "@/utils/unitMark";
import { Accordion } from "@mantine/core";
import { t } from "i18next";

const WorkCurrentDefect = Object.assign({}, {
    Title: BaseTitle,
    Table: BaseTable,
    Accordion: BaseAccordion
})


export type DefectObject = {
    [key: string]: DefectObjectProps
};

export interface DefectObjectProps {
    defectQuantity: string;
    reworkQuantity?: string;
    actualDefectQuantity?: string;
    defectName?: string;
    defectCode?: string;
}

export const WorkCurrentDefectComponent = () => {

    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));

    const { work } = workData

    const currentDefectList = Object.keys(work?.currentLotSummary?.defect ? work?.currentLotSummary?.defect as DefectObject : {}).map((code) => {
        return {
            defectName: (work?.currentLotSummary?.defect as DefectObject)[code]?.defectName as string,
            defectCode: code,
            defectQuantity: (work?.currentLotSummary?.defect as DefectObject)[code]?.defectQuantity as string,
            setUnitText: work?.item?.unitText
        }
    })

    return (
        <WorkCurrentDefect.Accordion>
            <Accordion.Item value="currentDefectTable">
                <Accordion.Control>
                    <WorkCurrentDefect.Title
                        fz={"1.625rem"}
                        c={"#FFFFFF"}
                    >
                        {
                            customFunctions.ADD_TEMP_FUNCTION_ENABLED ? t("현재 로트 불량기록") : t("불량기록")
                        }
                    </WorkCurrentDefect.Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <WorkCurrentDefect.Table
                        columns={[t("불량명"), t("보고수량")]}
                        flex={[2, 1]}
                        data={currentDefectList?.map((defect) => {
                            return defect?.defectQuantity !== '0' ? [
                                defect?.defectName ?? "-",
                                <div style={{ textAlign: 'right' }}>{(setToLocaleString(defect?.defectQuantity)) + defect?.setUnitText}</div>,
                            ] : [];
                        }) ?? [[]]}
                    />
                </Accordion.Panel>
            </Accordion.Item>
        </WorkCurrentDefect.Accordion>
    )
}