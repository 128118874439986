import BaseAccordion from "@/components/base/BaseAccordion";
import { BaseBox } from "@/components/base/Basebox";
import BaseProgress from "@/components/base/BaseProgress";
import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseButton } from "@/components/base/button/BaseButton";
import { BaseTable } from "@/components/base/table/BaseTable";
import { customFunctions } from "@/config/customFunctions";
import useConfirmModal from "@/hooks/useConfirmModal.hook";
import useWorkActions from "@/hooks/useWorkActions.hook";
import { useWorkDataStore } from "@/store/work.store";
import { setToLocaleString } from "@/utils/unitMark";
import { Accordion, Flex } from "@mantine/core";
import { IconRefreshDot } from "@tabler/icons-react";
import dayjs from "dayjs";
import { t } from "i18next";


const WorkInventories = Object.assign({}, {
    Box: BaseBox,
    Text: BaseText,
    Button: BaseButton,
    Title: BaseTitle,
    Table: BaseTable,
    Progress: BaseProgress,
    Accordion: BaseAccordion
})

export const WorkInventoriesComponent = () => {


    const { workData } = useWorkDataStore((state) => ({
        workData: state.workData,
    }));
    const { work, outsource, inventories } = workData
    const { confirmFlushModal } = useConfirmModal();
    const { onFlush } = useWorkActions({ workData: work });

    return (

        <WorkInventories.Accordion>
            <Accordion.Item value="ledgerTable">
                <Accordion.Control>
                    <Flex w={"100%"} justify={"space-between"}>
                        <WorkInventories.Title
                            fz={"1.625rem"}
                            c={"#FFFFFF"}
                        >
                            {t("생산가능재고")}
                        </WorkInventories.Title>
                        {work?.routingOutsourceId && (
                            <WorkInventories.Box>{`${t("외주")} : ${outsource?.name}`}</WorkInventories.Box>
                        )}
                        {
                            customFunctions.ADD_FLUSHING_BUTTON &&
                            <WorkInventories.Button
                                color="gray.5"
                                size="md"
                                onClick={async () => {
                                    if (inventories?.rows?.length as number > 0) {
                                        const confirmFlush = await confirmFlushModal({ workInventoriesData: inventories });
                                        if (confirmFlush) { onFlush(); }
                                    }
                                }}
                                rightIcon={<IconRefreshDot size={'2rem'} />}
                            >
                                {t("재고 초기화")}
                            </WorkInventories.Button>
                        }
                    </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                    <WorkInventories.Table
                        columns={[t("품목코드"), t("로트명"), t("로트유효기한"), t("잔량")]}
                        flex={[2, 2, 1, 1]}
                        data={
                            inventories?.rows?.map((row) => {
                                return [
                                    row.itemCode,
                                    row.lot?.name ?? "-",
                                    row.lot?.expiration
                                        ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
                                        : "-",
                                    <div style={{ textAlign: "right" }}>{(setToLocaleString(row.quantity ?? '') + " " + row.unitText)}</div>,

                                ];
                            }) as string[][]
                        }
                    />
                </Accordion.Panel>
            </Accordion.Item>
        </WorkInventories.Accordion >
    )
}  