import useInventoriesGetQuery from "@/api/inventories/useInventoriesGetQuery";
import {
  INVENTORIES_KEY,
  mutateInventories,
} from "@/api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "@/api/logs/useWorksLogsQuery";
import { InventoriesAutoComplete } from "@/components/autoComplete/inventory/inventories-autoComplete";
import { ItemAutoComplete } from "@/components/autoComplete/item/item-autoComplete";
import { LotAutoComplete } from "@/components/autoComplete/lots/lots-autoComplete";
import { InventoryBarcodeInput } from "@/components/barcode/inventoriesBarcodeInput";
import { FormButtonBox } from "@/components/form/FormButtonBox";
import { FormMain } from "@/components/form/FormMain";
import { FormWrapper } from "@/components/form/FormWrapper";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import {
  Button,
  Flex,
  Group,
  Loader,
  Radio,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ProductionActionApiWorksWorkIdInputPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Select: Select,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  LotAutoComplete: LotAutoComplete,
  InventoriesAutoComplete: InventoriesAutoComplete,
  ItemAutoComplete: ItemAutoComplete,
});

export interface WorkInputFormProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined;
  lotId?: number;
  onInputSuccess?: () => void;
}

export interface RoutingBomProps {
  itemCode: string;
  routingCode: string;
  item: {
    name: string;
  };
}

export const RawMaterialInputForm = ({
  workData,
  onInputSuccess,
}: WorkInputFormProps) => {
  const [itemCodes, setItemCodes] = useState<string | undefined>();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: InventoriesMutate, isLoading } = useMutation(
    (params: ProductionActionApiWorksWorkIdInputPutRequest) =>
      mutateInventories.workInput(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INVENTORIES_KEY);
        queryClient.invalidateQueries(WORK_LOGS_KEY);
        customNotification.success({
          message: t("원/부자재가 성공적으로 투입되었습니다."),
        });
        onInputSuccess && onInputSuccess(); // 실적 저장시 원부자재 투입이 된다면 실적저장 함수를 실행
        closeModal(true);
      },
      onError: () => {
        customNotification.error({
          message: t("원/부자재 투입에 실패하였습니다."),
        });
      },
    }
  );

  const form = useForm({
    initialValues: {
      barcodeInput: "",
      workId: workData?.id,
      worksWorkIdInputPutRequest: {
        lotId: "",
        sourceLocationCode: undefined,
        // routingsData는 배열이므로, find로 해당 routingCode에 맞는 데이터를 찾아야 함
        targetLocationCode:
          workData?.equipment?.toLocationCode ??
          workData?.locationSetting?.toLocation?.code ??
          workData?.routingData?.operation?.toLocationCode ??
          workData?.productionPlan?.routingsData?.find(
            (data: any) => data?.code === workData?.routingCode
          )?.operation?.toLocationCode,
        quantity: "0",
      },
    },
  });

  useEffect(() => {
    if (workData) {
      // console.log('workData 인식, 폼 초기화')
      form.setValues({
        barcodeInput: "",
        workId: workData.id,
        worksWorkIdInputPutRequest: {
          lotId: "",
          sourceLocationCode: undefined,
          targetLocationCode:
            // workData?.currentRoutingOutsourceData?.toLocationCode ??
            workData?.equipment?.toLocationCode ??
            workData?.locationSetting?.toLocation?.code ??
            workData?.routingData?.operation?.toLocationCode ??
            workData?.productionPlan?.routingsData?.find(
              (data: any) => data?.code === workData?.routingCode
            )?.operation?.toLocationCode,
          quantity: "0",
        },
      });
    }
  }, [workData]);

  useInventoriesGetQuery(
    form.values.worksWorkIdInputPutRequest.lotId
      ? {
          query: {
            $and: [
              {
                itemCode:
                  itemCodes ??
                  workData?.routingData?.routingBoms?.map(
                    (value) => value?.itemCode
                  ),
              },
              {
                lotId: { $eq: form.values.worksWorkIdInputPutRequest.lotId },
              },
              {
                locationCode:
                  workData?.locationSetting?.fromLocation?.code ??
                  workData?.routingData?.operation?.fromLocationCode ??
                  workData?.productionPlan?.routingsData?.find(
                    (data: any) => data?.code === workData?.routingCode
                  )?.operation?.fromLocationCode,
              },
              {
                quantity: { $gt: 0 },
              },
            ],
          },
        }
      : undefined,
    {
      onSuccess: (data) => {
        if (data.rows && data.rows.length > 0) {
          form.setFieldValue(
            "worksWorkIdInputPutRequest.quantity",
            data.rows[0].quantity
          );
        } else {
          form.setFieldValue("worksWorkIdInputPutRequest.quantity", 0);
          customNotification.error({
            message: t("바코드에 해당하는 로트가 존재하지 않습니다."),
          });
        }
        form.setFieldValue("barcodeInput", "");
        form.setFieldValue("lotId", "");
        // if (data.rows) {
        //   if (data.rows.length === 0) {
        //     form.setFieldValue('worksWorkIdInputPutRequest.quantity', 0)
        //     form.setFieldValue('barcodeInput', '')
        //     form.setFieldValue('lotId', '')
        //     return customNotification.error({ message: '바코드에 해당하는 로트가 존재하지 않습니다.' })
        //   }
        //   form.setFieldValue('worksWorkIdInputPutRequest.quantity', data?.rows[0]?.quantity)
        //   form.setFieldValue('barcodeInput', '')
        //   form.setFieldValue('lotId', '')
        // }
      },
    }
  );

  const onSubmit = () => {
    try {
      InventoriesMutate({
        workId: form?.values?.workId as number,
        worksWorkIdInputPutRequest: {
          lotId:
            // form?.values?.worksWorkIdInputPutRequest?.lotId &&
            parseInt(form?.values?.worksWorkIdInputPutRequest?.lotId),
          sourceLocationCode:
            workData?.locationSetting?.fromLocation?.code ??
            workData?.routingData?.operation?.fromLocationCode,
          targetLocationCode:
            form.values.worksWorkIdInputPutRequest.targetLocationCode &&
            (form.values.worksWorkIdInputPutRequest
              .targetLocationCode as string),
          quantity:
            form.values.worksWorkIdInputPutRequest.quantity &&
            (form.values.worksWorkIdInputPutRequest.quantity as string),
        },
      });
    } catch (e) {
      customNotification.error({
        message: t("원/부자재 투입에 실패하였습니다."),
      });
    }
  };

  const invalidConfirm =
    form.values.worksWorkIdInputPutRequest.lotId === undefined ||
    form.values.worksWorkIdInputPutRequest.quantity === "0" ||
    form.values.worksWorkIdInputPutRequest.quantity === "";

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!form.values.barcodeInput) return;
      form.setFieldValue(
        "worksWorkIdInputPutRequest.lotId",
        form.values.barcodeInput
      );
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) return;
    form.setFieldValue("worksWorkIdInputPutRequest.lotId", inputValue);
  };

  const itemCodeList =
    itemCodes ??
    workData?.routingData?.routingBoms?.map((value) => value.itemCode);

  if (isLoading)
    return (
      <Flex w="100%" h="100%" justify="center" align="center">
        <Loader size="30rem" />
      </Flex>
    );
  return (
    <>
      <Create.Wrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <span
                  style={{ marginInline: "3rem 12rem", fontSize: "1.4rem" }}
                >
                  {t("품목코드")}
                </span>
                <span
                  style={{ fontSize: "1.4rem", marginInline: "2rem 16rem" }}
                >
                  {t("품목명")}
                </span>
                <span style={{ fontSize: "1.4rem" }}>{t("라우팅코드")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <Radio.Group
              value={itemCodes}
              onChange={(e) => {
                setItemCodes(e);
                form.setFieldValue(
                  "worksWorkIdInputPutRequest.lotId",
                  undefined
                );
              }}
            >
              {workData?.routingData?.routingBoms?.map(
                (value, index: number) => {
                  return (
                    <tr key={index}>
                      <Group style={{ gap: "2rem" }}>
                        <Radio value={value.itemCode} />
                        <td width="180rem">{value?.itemCode}</td>
                        <td width="180rem">{value?.item?.name}</td>
                        <td width="180rem">{value?.routingCode}</td>
                      </Group>
                    </tr>
                  );
                }
              )}
            </Radio.Group>
          </tbody>
        </Table>
        <InventoryBarcodeInput
          // value={lotId as string}
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
        />
        <Create.InventoriesAutoComplete
          key={form.values.worksWorkIdInputPutRequest.lotId}
          label={t("로트 정보")}
          withAsterisk
          {...form.getInputProps("worksWorkIdInputPutRequest.lotId")}
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}
          query={{
            $and: [
              {
                itemCode: Array.isArray(itemCodeList)
                  ? itemCodeList
                  : [itemCodeList],
              },
              {
                locationCode:
                  workData?.locationSetting?.fromLocation?.code ??
                  workData?.routingData?.operation?.fromLocationCode ??
                  workData?.productionPlan?.routingsData?.find(
                    (data: any) => data.code === workData?.routingCode
                  )?.operation?.fromLocationCode,
              },
              {
                quantity: { $gt: 0 },
              },
            ],
          }}
        />
        <Create.Input
          label={t("출고 로케이션 코드")}
          withAsterisk
          disabled
          size="lg"
          value={
            workData?.currentRoutingOutsourceData?.fromLocationCode ??
            workData?.locationSetting?.fromLocation?.code ??
            workData?.routingData?.operation?.fromLocationCode
          }
          styles={{ label: { fontSize: "0.9em" } }}
        />
        <Create.Input
          label={t("입고 로케이션 코드")}
          disabled
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}
          {...form.getInputProps(
            "worksWorkIdInputPutRequest.targetLocationCode"
          )}
        />
        <Create.Input
          withAsterisk
          type="number"
          label={t("수량")}
          size="lg"
          styles={{ label: { fontSize: "0.9em" } }}
          {...form.getInputProps("worksWorkIdInputPutRequest.quantity")}
        />
        <Create.ButtonBox>
          <Create.Button
            color="gray"
            onClick={() => closeModal(false)}
            size="lg"
            fz="xl"
          >
            {t("취소")}
          </Create.Button>
          <Create.Button
            disabled={invalidConfirm}
            onClick={onSubmit}
            size="lg"
            fz="xl"
          >
            {t("저장")}
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
