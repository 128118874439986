import { works } from "@/api/work/useWorkQuery";
import DateRangePicker from "@/components/date/DateRangePicker";
import { WorkRow } from "@/components/row/WorkRow";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import { useWorksSearchActions } from "@/hooks/useWorksActions.hook";
import { useWorksSearchState } from "@/hooks/useWorksState.hook";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import {
  ActionIcon,
  Chip,
  Flex,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const { WAITING, PAUSED, WORKING, DONE } =
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;

export const Works = () => {
  const { openModal, closeModal } = useModal();
  const { equipmentCode } = useLocalStorage();
  const { activePage, dates, value, item, spec } = useWorksSearchState();
  const { setActivePage, setDates, setValue, setItem, setSpec } =
    useWorksSearchActions();
  const { t } = useTranslation();

  const { data, refetch } = useQuery(
    works.itemWork({
      page: activePage ?? 1,
      query: {
        $and: [
          equipmentCode !== null && equipmentCode !== "null"
            ? { equipmentCode: { $eq: equipmentCode } }
            : {},
          {
            trackingStatus:
              value.length > 0 ? value : [WAITING, PAUSED, WORKING, DONE],
          },
          {
            itemDescription: {
              $eq: null,
            },
          },
          ...(item
            ? [
                {
                  itemName: {
                    $eq: item,
                  },
                },
              ]
            : []),
          ...(spec
            ? [
                {
                  spec: {
                    $eq: spec,
                  },
                },
              ]
            : []),
        ],
      },
      sort: "-createdAt",
      dates,
      pageSize: 10,
      populate: [
        "equipment",
        "summary",
        "item",
        "productionPlan",
        "routingData",
      ],
    })
  );

  const { data: findData, isLoading } = useQuery(
    works.itemWorkFind({
      query: {
        $and: [
          equipmentCode !== null && equipmentCode !== "null"
            ? { equipmentCode: { $eq: equipmentCode } }
            : {},
          {
            trackingStatus:
              value.length > 0 ? value : [WAITING, PAUSED, WORKING, DONE],
          },
          ...(item
            ? [
                {
                  itemName: {
                    $eq: item,
                  },
                },
              ]
            : []),
          ...(spec
            ? [
                {
                  spec: {
                    $eq: spec,
                  },
                },
              ]
            : []),
        ],
      },
      sort: "-createdAt",
      dates,
      pageSize: 10,
      populate: [
        "equipment",
        "summary",
        "item",
        "productionPlan",
        "routingData",
      ],
    })
  );

  const itemSelect =
    findData && Array.isArray(findData.data)
      ? findData.data
          ?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.itemName === item.itemName)
          )
          ?.map((item) => {
            return {
              value: item.itemName ?? "",
              label: `${item.itemName}` ?? "",
            };
          })
      : [];

  const itemSpecs =
    findData && Array.isArray(findData.data)
      ? findData.data
          ?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.spec === item.spec) && item.spec
          )
          ?.map((item) => {
            return {
              value: item.spec ?? "",
              label: item.spec ?? "-",
            };
          })
      : [];

  useEffect(() => {
    setActivePage(1);
    refetch();
  }, [dates, refetch, value, item, spec]);
  if (isLoading)
    return (
      <Flex w="100%" h="100%" justify="center" align="center">
        <Loader size="30rem" />
      </Flex>
    );
  return (
    <Container>
      <HeaderContent>
        <Flex direction={"column"} gap="sm">
          <CalendarContent>
            <TimeContent>
              {dayjs(dates[0] ?? undefined).format("YYYY-MM-DD")} ~{" "}
              {dayjs(dates[1] ?? undefined).format("YYYY-MM-DD")}
            </TimeContent>
            <ActionIcon
              size="2rem"
              onClick={() => {
                openModal(
                  <DateRangePicker dates={dates} />,
                  null,
                  "날짜 선택",
                  true
                ).then((result: [Date, Date]) => {
                  if (Array.isArray(result)) setDates(result);
                });
              }}
            >
              <IconCalendar size="2rem" />
            </ActionIcon>
          </CalendarContent>
          <Flex gap="sm">
            <Select
              placeholder={t("품목을 선택해주세요")}
              onChange={(code) => setItem(code ?? "")}
              data={itemSelect ?? []}
              value={item}
              size="md"
              clearable
              searchable
            />
            <Select
              data={itemSpecs ?? []}
              size="md"
              placeholder={t("규격을 선택해주세요")}
              value={spec}
              onChange={(value) => setSpec(value ?? "")}
              clearable
              searchable
            />
          </Flex>
        </Flex>
        <Flex gap="md" justify="center" align="center" direction="row">
          <Chip.Group multiple value={value} onChange={setValue}>
            <Group position="center">
              <StatusChip
                value={WAITING}
                variant="filled"
                size="sm"
                radius="xs"
              >
                {t("작업대기")}
              </StatusChip>
              <StatusChip
                value={WORKING}
                variant="filled"
                size="sm"
                radius="xs"
              >
                {t("작업중")}
              </StatusChip>
              <StatusChip value={PAUSED} variant="filled" size="sm" radius="xs">
                {t("일시정지")}
              </StatusChip>
              <StatusChip value={DONE} variant="filled" size="sm" radius="xs">
                {t("작업완료")}
              </StatusChip>
            </Group>
          </Chip.Group>
        </Flex>
      </HeaderContent>
      <Table verticalSpacing={"md"} horizontalSpacing={"md"}>
        <thead>
          <tr>
            <th>
              <Text fz="md" fw={500} span>
                {t("작업 순서")}
              </Text>
            </th>
            <th>
              <Text fz="md" fw={500} span>
                {t("라인명(설비명)")}
              </Text>
            </th>
            <th>
              <Div>
                <Text fz="md" fw={500} span>
                  {t("제품(라우팅코드)")}
                </Text>
              </Div>
            </th>
            <th>
              <Text c={theme.colors?.blue?.[9]} fz="md" fw={500} span>
                {t("계획 수량")}
              </Text>
            </th>
            <th>
              <Text fz="md" fw={500} span>
                {t("규격")}
              </Text>
            </th>
            <th>
              <Text fz="md" fw={500} span>
                {t("작업일")}
              </Text>
            </th>
            <th>
              <Text fz="md" fw={500} span>
                {t("상태")}
              </Text>
            </th>
            <th>
              <Text fz="md" fw={500} span>
                {t("비고")}
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.data.rows?.map((work, index) => {
            return <WorkRow key={work.id} data={work} />;
          })}
        </tbody>
      </Table>
      <Flex justify={"center"} w={"100%"} mt={50}>
        <Pagination
          align="center"
          onChange={setActivePage}
          value={activePage}
          total={data?.data?.totalPages ?? 0}
          size=""
          radius="sm"
        />
      </Flex>
    </Container>
  );
};

const Th = styled.th<{ seq?: number; flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: pre-line;
  word-break: break-all;
  width: auto;
  max-width: 100%;
  min-width: 140px;
  /* border: 0.0625rem solid ${theme.colors?.gray?.[3]}; */
`;

const Div = styled.div`
  display: flex;
  width: 50%;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 8rme;
  align-self: stretch;
`;

const TimeContent = styled(Title)`
  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: white;
`;

const CalendarContent = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
`;

const StatusChip = styled(Chip)`
  label {
    height: 4rem;
  }
`;
const Container = styled.div`
  overflow: auto;
  height: 100%;
`;
