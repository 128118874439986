import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/item/useItemGetQuery";
import { mutateLots } from "@/api/lots/useLotsQuery";
import { ItemAutoComplete } from "@/components/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/components/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/components/autoComplete/lots/lot-selectComplete";
import { LotAutoComplete } from "@/components/autoComplete/lots/lots-autoComplete";
import useGetLotsIdQuery from "@/components/autoComplete/lots/select-useGetLotsQuery";
import { FormButtonBox } from "@/components/form/FormButtonBox";
import { FormWrapper } from "@/components/form/FormWrapper";
import { LotForm } from "@/components/form/lots/form";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import { Button, Flex, Space, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  DefaultApiLotsPostRequest,
  DefaultApiWmsIncomingPostRequest,
  WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { EventHandler, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Create = Object.assign(
  {},
  {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
    LotAutoComplete: LotAutoComplete,
  }
);

interface LotMutationFromProps {
  name: string;
  expiredAt: string;
  itemCode: string;
  id: number;
}

interface IncomingFormProps {
  itemCode?: string;
  locationCode?: string;
}

export const IncomingForm = ({ itemCode, locationCode }: IncomingFormProps) => {
  const { openModal, closeModal } = useModal();
  // const { mutate: postMutate } = useInventoryMutation("incoming");
  const queryClient = useQueryClient();

  const { mutate: incomingMutate } = useMutation(
    (params: DefaultApiWmsIncomingPostRequest) =>
      mutateInventories
        .incoming(params)
        .mutationFn(params as DefaultApiWmsIncomingPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("inventories");
        customNotification.success({ message: "재고가 등록되었습니다." });
      },
      onError: () => {
        customNotification.error({ message: "재고 등록에 실패했습니다." });
      },
    }
  );
  const { mutate: postLotsMutate } = useMutation(
    (params: DefaultApiLotsPostRequest) =>
      mutateLots
        .create(params)
        .mutationFn(params as DefaultApiLotsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lots");
      },
    }
  );
  const [newLot, setNewLot] = useState<LotMutationFromProps>();
  const form = useForm<
    Partial<WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner>
  >({
    initialValues: {
      itemCode: itemCode,
      locationCode: locationCode,
      quantity: undefined,
      lotId: undefined,
      lotInfo: {
        name: undefined,
        expiration: undefined,
      },
    },
  });

  const { data: itemData } = useQuery(
    items.codeSearch({
      query: form.values.itemCode
        ? { $and: [{ code: { $eq: form.values.itemCode } }] }
        : "",
    })
  );

  const { data: lotData } = useGetLotsIdQuery(
    Number(form.values?.lotId) || Number(newLot?.id),
    !!form.values?.lotId || !!newLot?.id
  );

  const onSubmit = () => {
    const { itemCode, locationCode, quantity } = form.values;
    if (!itemCode && !locationCode && !quantity) return;

    incomingMutate({
      workLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInner: {
        itemCode: form.values.itemCode as string,
        locationCode: form.values.locationCode as string,
        quantity: form.values.quantity as string,
        lotId: Number(form.values.lotId) || Number(newLot?.id),
        lotInfo: {
          name:
            form.values.itemCode && lotData?.name
              ? lotData?.name
              : newLot?.name,
          expiration:
            form.values.itemCode && lotData?.expiration
              ? lotData?.expiration
              : newLot?.expiredAt,
        },
        actionFrom: "WEB",
      },
    });
    closeModal({});
  };

  const onCloseHandler: EventHandler<any> = (values) => {
    postLotsMutate(
      {
        lotsGetRequest: {
          name: values.name!,
          expiration: values.expiredAt!,
          itemCode: values.itemCode!,
        },
      },
      {
        onSuccess: (data) => {
          setNewLot(data?.data as LotMutationFromProps | undefined);
          alert("로트를 생성했습니다.");
        },
      }
    );
    closeModal(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <LotForm
        itemCodeValue={form.values.itemCode as string}
        onSubmit={onCloseHandler}
      />,
      null,
      "신규 LOT 등록",
      true
    ).then((result) => {});
  };

  return (
    <>
      <Create.Wrapper>
        <Create.ItemAutoComplete
          label="품목코드"
          withAsterisk
          {...form.getInputProps("itemCode")}
        />
        <Create.LocationAutoComplete
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
        />
        <Create.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
        />
        <Flex justify="center" align="center" w="100%">
          <LotSelectComplete
            label="LOT 정보"
            description={`LOT 관리 기준 : 
            ${
              form.values.itemCode &&
              itemData?.data?.rows &&
              itemData?.data?.rows[0]?.usesLotName &&
              itemData?.data?.rows[0]?.usesLotExpiration
                ? "이름 + 만료일"
                : form.values.itemCode &&
                  itemData?.data?.rows &&
                  itemData?.data?.rows[0]?.usesLotName
                ? "이름"
                : form.values.itemCode &&
                  itemData?.data?.rows &&
                  itemData?.data?.rows[0]?.usesLotExpiration
                ? "만료일"
                : "관리 안함"
            }`}
            itemCodeValue={
              (form.values.itemCode as string) || (lotData?.itemCode as string)
            }
            newIdValue={lotData?.id}
            dropdownPosition="bottom"
            {...form.getInputProps("lotId")}
            defaultValue={lotData && lotData?.id!.toString()}
            mr="sm"
            styles={
              lotData
                ? {
                    description: { fontSize: 10, color: "red" },
                    input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                  }
                : {
                    description: { fontSize: 10, color: "red" },
                  }
            }
            disabled={
              (itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName) ||
              (itemData?.data?.rows &&
                itemData?.data?.rows[0]?.usesLotExpiration)
                ? false
                : true
            }
          />
          <div>
            <Space h="xl" />
            <Space h="lg" />
            <Create.Button
              my="xl"
              disabled={
                (itemData?.data?.rows &&
                  itemData?.data?.rows[0]?.usesLotName) ||
                (itemData?.data?.rows &&
                  itemData?.data?.rows[0]?.usesLotExpiration)
                  ? false
                  : true
              }
              onClick={addNewModalHandler}
            >
              신규로트 생성
            </Create.Button>
          </div>
        </Flex>
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button onClick={onSubmit}>재고 입고</Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
