import { INVENTORIES_KEY } from "@/api/inventories/useInventoriesQuery";
import { WORK_LOG_GET_KEY } from "@/api/logs/useWorkLogsGetQuery";
import { WORK_KEY } from "@/api/work/useWorkQuery";
import { ProductionInstance } from "@/instance/axios";
import { customNotification } from "@/utils/notificationShow";
import { ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

//삭제
const fetchWorkLogsWorkLogIdCancelDefectPut = ({
    workLogId,
}: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest) =>
    ProductionInstance.workLogsWorkLogIdCancelDefectPut({
        workLogId,
    });

export const useWorkLogsWorkLogIdCancelDefectPut = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        ({
            workLogId,
        }: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest) =>
            fetchWorkLogsWorkLogIdCancelDefectPut({ workLogId }),
        {
            onSuccess: () => {
                customNotification.success({
                    message: t("불량이 성공적으로 취소되었습니다."),
                });
                queryClient.invalidateQueries(WORK_KEY)
                queryClient.invalidateQueries(WORK_LOG_GET_KEY);
                queryClient.invalidateQueries(INVENTORIES_KEY);
            },
            onError: (error: any) => {
                customNotification.error({
                    message: error.response.data.message
                });
            }
        }
    );
};
export default useWorkLogsWorkLogIdCancelDefectPut;
