import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useState } from "react";

interface MantineSelectableTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  onRowSelection?: (selectedRows: TData[]) => void; // 선택된 로우 변경 시 호출되는 핸들러
  doReset: boolean; // 선택된 로우 초기화 시 호출되는 핸들러
}

export const MantineSelectableTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowSelection,
  doReset,
  ...props
}: MantineSelectableTableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    if (onRowSelection) {
      const selectedRows = Object.keys(rowSelection)
        .filter((key) => rowSelection[key]) // 선택된 로우만 필터링
        .map((key) => data[Number(key)]);
      onRowSelection(selectedRows);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (doReset) {
      setRowSelection({});
    }
  }, [doReset]);
  const table = useMantineReactTable<TData>({
    columns,
    data: data,
    enableRowSelection: (row) =>
      Number(row.original.end) < Number(row.original.targetQuantity),
    mantineSelectCheckboxProps: ({ row }) => ({
      disabled: Number(row.original.end) >= Number(row.original.targetQuantity),
      styles: (theme: any) => ({
        input: {
          "&:disabled": {
            backgroundColor: theme.colors.gray[6],
            color: theme.colors.gray[5],
            border: `1px solid ${theme.colors.gray[5]}`,
          },
        },
      }),
    }),
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    enableRowActions: false,
    positionActionsColumn: "last",
    mantineTableContainerProps: { sx: { maxHeight: "20rem" } },
    mantineTableBodyCellProps: ({ cell }) => {
      return {
        bg: "#161B21",
      };
    },
    mantineTableProps: {
      fontSize: "1.0rem",
      c: "white",
    },
    mantineTableHeadCellProps: {
      bg: "#161B21",
      style: {
        color: "gray",
        fontSize: "1.0rem", // 강제 적용
      },
    },
    state: {
      rowSelection,
    },
    ...props,
    defaultColumn: {
      size: 30,
    },
  });

  return <MantineReactTable table={table} />;
};
