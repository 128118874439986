import { BaseHeader } from "@/components/base/BaseHeader";
import BaseBody from "@/components/base/body";
import { BaseButton } from "@/components/base/button/BaseButton";
import { MainContent } from "@/components/base/content/mainContent";
import { WorkStatusButton } from "@/components/button/workStatusButton";
import { WorkActionHeader } from "@/components/header/WorkActionHeader";
import { WorkInventoriesComponent } from "@/components/section/WorkInventoriesComponent";
import { WorkLocationComponent } from "@/components/section/WorkLocationComponent";
import { useGetWorkData } from "@/hooks/useGetWorkData.hook";
import InfoSection from "@/pages/nonStandardWork/InfoSection";
import { TableSection } from "@/pages/nonStandardWork/TableSection";

const NonStandard = Object.assign(
  {},
  {
    Body: BaseBody,
    Button: BaseButton,
    StatusButton: WorkStatusButton,
    Header: BaseHeader,
    Content: MainContent,
  }
);

export const NonStandardWork = () => {
  useGetWorkData();

  return (
    <NonStandard.Body>
      <div style={{ width: "100%" }}>
        <WorkActionHeader type="nonStandard" />;
        <InfoSection />
        <div style={{ marginBottom: "1rem" }}>
          <TableSection />
        </div>
        <WorkInventoriesComponent />
        <WorkLocationComponent />
      </div>
    </NonStandard.Body>
  );
};
