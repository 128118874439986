import { TrackingInstance } from "@/instance/axios";
import {
  createMutationKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import { ProductionTimeTrackingActionApiWorksGroupTrackingForceEndPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const FORCE_KEY = "force";

export const mutateForce = createMutationKeys(FORCE_KEY, {
  force: (
    params: ProductionTimeTrackingActionApiWorksGroupTrackingForceEndPostRequest
  ) => ({
    mutationKey: [params.worksGroupTrackingForceEndPostRequest],
    mutationFn: () => TrackingInstance.worksGroupTrackingForceEndPost(params),
  }),
});

export const defectQueryKeys = mergeQueryKeys(mutateForce);
