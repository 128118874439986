import { BaseText } from "@/components/base/BaseText";
import { BaseTitle } from "@/components/base/BaseTitle";
import { BaseTable } from "@/components/base/table/BaseTable";
import { FormButtonBox } from "@/components/form/FormButtonBox";
import { FormMain } from "@/components/form/FormMain";
import { FormWrapper } from "@/components/form/FormWrapper";
import useWorkActions from "@/hooks/useWorkActions.hook";
import { usePaginationStore } from "@/store/pagenation.store";
import { useWorkDataStore } from "@/store/work.store";
import { Button, Flex, Loader, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Logs = Object.assign({}, FormMain, {
  Button: Button,
  Description: BaseTitle,
  Text: BaseText,
  Table: BaseTable,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Pagination: Pagination,
});

export function InputLogsForm() {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));

  const { inputLogs, work } = workData;
  const { onCancel, cancelLoading } = useWorkActions({ workData: work });

  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
  }, [setPage]);

  if (cancelLoading)
    return (
      <Flex w="100%" h="100%" justify="center" align="center">
        <Loader size="30rem" />
      </Flex>
    );
  return (
    <Logs.Wrapper>
      <Logs.Description c={"#010000"} size={"sm"} fw={400}>
        {t("원/부자재 투입 기록을 확인합니다.")}
      </Logs.Description>
      <Logs.Description c={"#000000"} size={"sm"} fw={400}>
        {t("취소 버튼을 클릭하여 특정 투입을 취소할 수 있습니다.")}
      </Logs.Description>
      <Logs.Table
        fontSize={"sm"}
        withBorder={false}
        withColumnBorders={false}
        theadColor={"#FFFFFF"}
        flex={[1, 1, 2, 2, 1, 2, 2, 2]}
        trColor={"#000000"}
        columns={[
          t("품목코드"),
          t("로트명"),
          t("로트유효기한"),
          t("출고로케이션"),
          t("수량"),
          t("입고로케이션"),
          t("작업자"),
          t("일시"),
          t("액션"),
        ]}
        data={
          inputLogs?.rows?.flatMap((row: WorkLogsGet200ResponseRowsInner) => {
            const inputRow = [
              row.logData?.incoming?.itemCode || "-",
              row.logData?.incoming?.lotData?.name || "-",
              row.logData?.incoming?.lotData?.expiration
                ? dayjs(row.logData?.incoming?.lotData?.expiration).format(
                    "YYYY-MM-DD"
                  )
                : "-",
              row.logData?.outgoing?.locationData?.name,
              <div style={{ textAlign: "right" }}>
                {row.logData?.incoming?.quantity + " " + (row.unitText ?? "")}
              </div>,
              row.logData?.incoming?.locationData?.name,
              row.logData?.incoming?.userCode,
              dayjs(row.logData?.incoming?.createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              row.alreadyCanceledWorkLog ? (
                <Logs.Text c={"grape"} size={"xl"} ta={"center"}>
                  {t("취소됨")}
                </Logs.Text>
              ) : (
                <Logs.Button
                  bg={"inherit"}
                  styles={{
                    root: {
                      "&:hover": {
                        background: "inherit",
                      },
                    },
                  }}
                  onClick={() => onCancel(row?.id as number)}
                >
                  <IconTrash color="red" />
                  <Logs.Text pl={"1rem"} c={"red"} size={"xl"}>
                    {t("취소")}
                  </Logs.Text>
                </Logs.Button>
              ),
            ];
            return [inputRow];
          }) as string[][]
        }
      />
      <Flex justify={"center"}>
        <Logs.Pagination
          size={"xl"}
          value={currentPage}
          onChange={setPage}
          total={inputLogs?.totalPages as number}
        />
      </Flex>
    </Logs.Wrapper>
  );
}
